:root {
    --icon-files-list-width: 128px;
}
.icon-files-list-container {
    padding: 8px;
    display: grid;
    grid-gap: 1em;
    grid-template-columns: repeat(
        auto-fit,
        minmax(var(--icon-files-list-width), 2fr)
    );
}

.icons-files-list-file-icon {
    width: var(--icon-files-list-width);
    height: var(--icon-files-list-width);
    border: none;
    background-repeat: no-repeat;
    background-size: cover;
}

.icon-fiels-list-folder {
    display: flex;
    align-items: center;
    background-color: #f5f5f5;
    overflow: hidden;
}
.icon-fiels-list-file {
    background-color: #f5f5f5;
    overflow: hidden;
}

.icons-files-list-file-text {
    padding: 8px;
    display: flex;
    align-items: center;
    width: var(--icon-files-list-width);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
