:root {
    --topBarHeight: 68px;
    --sideNavTranslateWidth: 0px;
    --sideNavWidth: 245px;
    --sideNavDisplay: flex;
    --border: solid 1px black;
    --padding: 12px;
}

.lum-toolbox-main-content {
    padding: var(--padding);
    width: 100%;
}

.lum-toolbox-side-nav {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    -webkit-overflow-scrolling: touch;
    left: 0;
    right: auto;
    top: 0;
    outline: 0;
    width: var(--sideNavWidth);
    border-right: var(--border);
    transform: translate(var(--sideNavTranslateWidth));
    transition: cubic-bezier(0, 0, 0.2, 1);
    transition-property: var(--sideNavWidth);
    transition-delay: 2ms;
    transition-duration: 225ms;
}

.lum-toolbox-top-bar {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    z-index: 10;
    flex-shrink: 0;
    position: static;
    transform: translateZ(0);
    height: var(--topBarHeight);
    border: var(--border);
}

.lum-toolbox-add-button {
    padding: 16px;
}