/* tius2 is the short for toolbox-import-user-step2 */

.tius2-main-container {
  margin: 1rem;
  width: 80%;
}

.tius2-directory-select-container {
  margin: 1rem;
}
