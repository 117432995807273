.drive-picker-container {
    height: 100%;
    display: grid;
    grid-template-columns: 0.4fr 2fr;
    grid-template-rows: 0.2fr 2.1fr;
    grid-template-areas: 'menu header' 'menu main';
}

.drive-picker-menu {
    padding: 8px;
    grid-area: menu;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.drive-picker-menu-item {
    font-size: 0.875rem;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.87);
}

.drive-picker-main {
    grid-area: main;
}

.centered-div {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.centered-div2 {
    display: flex;
    align-items: center;
}

.driver-picker-footer {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    overflow: hidden;
}

.drive-picker-title-span {
    display: flex;
    -webkit-box-align: baseline;
    align-items: baseline;
    font-size: 24px;
    font-size: 1.2rem;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.87);
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 8px;
    margin-left: 16px;
}
